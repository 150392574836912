<template>
  <b-card footer-class="text-muted">
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-3" v-if="chargeInfo">
      <b-col cols="12" lg="12" >
        <b-form @submit.prevent="updateInfo(info)">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Cedula" label-for="Cedula">
                <b-form-input v-model="info.permit_no" placeholder="Cedula"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Especialidad" label-for="Especialidad">
                <b-form-input v-model="info.study_field" placeholder="Especialidad"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  style="width: 100%"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
              >
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col cols="12" lg="6">
        <div align="center" style="margin-top: 20px">
          <h1>Subir firma</h1>
        </div>

        <div class="mt-3" style="margin-top: 50px" align="center">


          <b-form-file
              @change="handleImage()"
              id="file_patient_signature"
              placeholder="Subir archivo."
              v-model="sendFile"
              drop-placeholder="Drop file here..."
          />
          <b-button
              @click="save"
              style="width: 100%; margin-top: 40px"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Guardar
          </b-button>
        </div>
      </b-col>
      <b-col  cols="12" lg="6">
        <div align="center" style="margin-top: 20px" v-if="imgFile" >
          <h1 >Firma actual</h1>
          <img :src="imgFile" alt="">
        </div>

      </b-col>
    </b-row>
  </b-card>


</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm,
  BOverlay, BFormFile
} from "bootstrap-vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import {mapActions, mapGetters} from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import vueSignature from "vue-signature"

export default {
  name: "Info",
  data() {
    return {
      chargeInfo: false,
      sendFile: null,
      fileSend: null,
      show: false,
      imgFile: null,
      info: {
        study_field: '',
        permit_no: '',
      },
      signature: '',
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      disabled: false,
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    vueSignature,
    BOverlay,
    BFormFile,
  },
  async created() {
    await this.getFullInfo();

  },
  methods: {
    ...mapActions("patient", ["getFindFullName", "updateUserDoctor",'updateFileDoctor']),
    async save() {
      if (!this.file_send){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Archivo requerido',
            icon: "error",
            variant: "warning",
          },
        });
        return
      }
      this.show =true;

      const send = {
        file: this.file_send
      }
      const response = await this.updateFileDoctor({id: this.getUser.id, data: send});
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se edito la información correctamente",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.imgFile = this.file_send;
        this.file_send = null;
        this.sendFile = null;
        this.show =false;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al editar información',
            icon: "error",
            variant: "warning",
          },
        });
        this.show =false;
      }
    },
    async handleImage() {
      const file = document.querySelector(`#file_patient_signature`).files[0];

      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        this.file_send = event.target.result;
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async getFullInfo() {
      this.show =true;
      const response = await this.getFindFullName(this.getUser.id);
      this.signature = response.signature.signature ? response.signature.signature : null;
      this.info.permit_no = response.signature.permit_no;
      this.info.study_field = response.signature.study_field;
      this.imgFile = 'data:image/png;base64,'+response.signatureFile;
      this.chargeInfo = true
      this.show =false;
    },
    async updateInfo(data) {

      const response = await this.updateUserDoctor({id: this.getUser.id, data: data});
      this.show =true;
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se edito la información correctamente",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.show =false;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al editar información',
            icon: "error",
            variant: "warning",
          },
        });
        this.show =false;
      }

    },

  },

  computed: {
    ...mapGetters('auth', ['getUser']),
  }
}
</script>

<style scoped>

</style>